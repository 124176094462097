import CTALink from "@components/elements/CTALink"
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon"
import React from "react"
import { Container } from "react-bootstrap"
import "./ContentLinks.scss"
import Animation from "@components/elements/Animation"
import { parseHTML } from "@lib/utils"
import { Link } from "gatsby"
import GGFXImage from "@components/elements/GGFXImage"

const ContentLinks = ({ module, ggfx_results, strapiID }) => {
  if (!module) return null

  const { title, heading, cards, file, column } = module
  return (
    <Animation animateInType="up" className="content-links-wrap section-m">
      <Container className="content-links-container">
        <div className="content-section">
          {/* <p className="heading">{heading}</p> */}
          <h2 className="title">{title}</h2>
        </div>
        <div className={`links-section ${column}`}>
          {cards?.map((card, i) => {
            if (!card) return null

            const { icon, description, cta, id, content } = card
            return (
              <Animation
                animateInType="up"
                delay={i * 200}
                wrapperClassName="link-item-wrap"
                className="link-item"
                key={id}
              >
                <div className="icon-section">
                {/* <GGFXImage
            ImageSrc={icon}
            altText={icon?.alternativeText || "icon"}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename={"page.cards.icon"}
            strapiID={strapiID}
          /> */}
                  <img src={icon?.url} alt={icon?.alternativeText || "icon"} loading="eager" />
                </div>
                <div className={`link-content ${column}`}>
                {column === "three" ?
                  <span className="link-title">
                    <span>{cta?.cta_label}</span>
                    {/* <ArrowUpRightIcon /> */}
                  </span> :
                  <CTALink file={file} cta={cta} className="link-title">
                    <span>{cta?.cta_label}</span>
                    <ArrowUpRightIcon />
                  </CTALink>}
                  {description ?
                  <p className="link-description">{description}</p>
                  : content?.data?.content ? <div className="link-description">
                    {parseHTML(content?.data?.content)}
                  </div> : null}
                </div>
              </Animation>
            )
          })}
        </div>
      </Container>
    </Animation>
  )
}

export default ContentLinks
