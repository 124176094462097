import CTALink from "@components/elements/CTALink"
import ArrowRight from "@components/icons/ArrowRight"
import { parseHTML, scrollToID } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./TileBlockAds.scss"
import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { isEmpty } from "lodash"
import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"

const TileBlockAds = ({
  module,
  ggfx_results,
  imagename,
  strapiID,
  className,
  id,
  isAds,
}) => {
  if (!module) return null
  const {
    image,
    title,
    heading,
    description,
    cta,
    icon_stats,
    img_align,
    bg_color,
    image_fit,
    style,
    video_url,
    design_title
  } = module

   const isBlueBg = bg_color === "blue"

  // eslint-disable-next-line no-use-before-define
  const CTA = getCTA(cta, { bg_color })

  return (
    <div
      className={clsx(
        "tile-block-wrapper provident-sda",
        isBlueBg ? "tile-blue-bg section-l-p" : "section-p",
        className,
        style === "ads_banner" ? "" : style,
        bg_color
      )}
      id={id}
    >
      <Container
        className={clsx("tile-block-container", {
          "align-img-right": img_align === "right",
          "contain-image": image_fit === "contain",
        })}
      >
        <Animation animateInType="left" wrapperClassName="img-section">
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename={imagename || "page.image.tile_img"}
            strapiID={strapiID}
          />
          {video_url && <PlayVideoButton videoUrl={video_url} />}
        </Animation>
        <Animation animateInType="right" wrapperClassName="content-section">
          {heading &&<p className="heading">{heading}</p>}
          {design_title && <div className="design_title">
          {parseHTML(design_title?.data?.design_title)}
          </div>}
          {title &&<h3 className="title">{title}</h3>}
          {description &&<div className="description">
            {parseHTML(description?.data?.description)}
          </div>}
          {!isEmpty(icon_stats) && (
            <div className="icon-stats-section">
              {icon_stats.map((item) => (
                <div className="icon-stat" key={item.id}>
                  <img src={item?.image?.url} alt="" />
                  <p className="text">{item?.text}</p>
                </div>
              ))}
            </div>
          )}
          {CTA}
        </Animation>
      </Container>
    </div>
  )
}

export const getCTA = (cta, { bg_color } = {}) => {
  let CTA = null

  const isBlueBg = bg_color === "blue"

  if (cta) {
    if (cta?.custom_link?.startsWith("#")) {
      CTA = (
        <button
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
          onClick={() => {
            scrollToID(cta.custom_link.slice(1))
          }}
        >
          <span>{cta.cta_label}</span>
          {/* <ArrowRight color="white" /> */}
        </button>
      )
    } else {
      CTA = (
        <CTALink
          cta={cta}
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
        >
          <span>{cta.cta_label}</span>
          {/* <ArrowRight color="white" /> */}
        </CTALink>
      )
    }
  }

  return CTA
}

export default TileBlockAds
